import * as QueryString from 'query-string';

const getLocation = () => window?.location ?? {};

export function joinSearchObj(obj) {
  const arr: string[] = [];
  for (const key in obj) {
    if (obj[key]) {
      arr.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
    }
  }
  return arr.join('&');
}

export const getQueryObj = (url?: string) => {
  let queryStr;
  if (url) {
    queryStr = url.includes('?') ? url.slice(url.indexOf('?')) : '?';
  } else {
    queryStr = window.location.search || '?';
  }
  const queryPairs = queryStr.slice(1).split('&');
  const result: Record<string, string | null | undefined> = {};
  for (let i = 0; i < queryPairs.length; i++) {
    const pair = queryPairs[i].split('=');
    if (pair[0]) {
      const key = decodeURIComponent(pair[0]);
      const val = pair[1] ? decodeURIComponent(pair[1]) : null;
      result[key] = val;
    }
  }
  return result;
};

export const getQueryVar = (name) => {
  const queryObj = getQueryObj();
  return queryObj[name];
};

export function getURLParam(_name: string, _url?: string) {
  let name = _name;
  let url = _url;
  if (!url) {
    url = window.location.href;
  }
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regexS = `[\\?&]${name}=([^&#]*)`;
  const regex = new RegExp(regexS);
  const results = regex.exec(url);
  return results == null ? null : results[1];
}

export function setQueryObj(obj: any): void {
  const location = getLocation();
  const queryString = QueryString.stringify(obj);
  try {
    window.history.replaceState(
      {},
      '',
      [
        location.protocol,
        '//',
        location.host,
        location.pathname,
        queryString ? `?${queryString}` : '',
        location.hash,
      ].join('')
    );
  } catch (err: any) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
}
