/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-access-state-in-setstate */

import { FC, useState } from 'react';
import Logo from '../../styles/images/pureprofile-logo-2x.png';
import { LanguageFlagPicker } from './LanguageFlagPicker';
import { useAppSelector } from '../../store';

export interface HeaderProps {}

export interface HeaderState {
  collapsed: boolean;
}

export const Header: FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const country = useAppSelector((s) => s.country);
  const language = useAppSelector((s) => s.language);

  const renderNav = () => {
    let menuClass = 'nav navbar-nav';
    if (collapsed) {
      menuClass += ' closed';
    }

    let links = country?.panel_links?.filter((l) => l.show_on_nav_bar) ?? [];
    if (links && language) {
      links = links.filter((l) => l.language_code === language.language_code);
    }
    if (links && links.length > 0) {
      return (
        <nav className="collapse navbar-collapse" role="navigation">
          <ul id="main-navigation" className={menuClass}>
            {links.map((l) => (
              <li key={l.sort_order}>
                <a href={l.link_url} rel="noopener noreferrer" target="_blank">
                  {l.link_name}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      );
    }
    return null;

    // default links when no links are defined on the panel yet
    // return (
    //   <nav className="collapse navbar-collapse" role="navigation">
    //     <ul id="main-navigation" className={menuClass}>
    //       <li>
    //         <a href="https://www.pureprofile.com/faq/" rel="noopener noreferrer" target="_blank">
    //           {t('faq', 'FAQ')}
    //         </a>
    //       </li>
    //       <li>
    //         <a href="https://www.pureprofile.com/8-ways-to-earn-more" rel="noopener noreferrer" target="_blank">
    //           {t('tips', 'Tips')}
    //         </a>
    //       </li>
    //       <li>
    //         <a href="https://www.pureprofile.com/contact-us/" rel="noopener noreferrer" target="_blank">
    //           {t('contactUs', 'Contact us')}
    //         </a>
    //       </li>
    //       <li>
    //         <a href="https://www.pureprofile.com/privacy-policy/" rel="noopener noreferrer" target="_blank">
    //           {t('privacy', 'Privacy')}
    //         </a>
    //       </li>
    //       <li>
    //         <a href="https://www.pureprofile.com/terms/" rel="noopener noreferrer" target="_blank">
    //           {t('terms', 'Terms')}
    //         </a>
    //       </li>
    //     </ul>
    //   </nav>
    // );
  };

  return (
    <header className="banner navbar navbar-default navbar-fixed-top" role="banner">
      <div style={{ position: 'absolute', left: '20px', top: '15px' }}>
        <LanguageFlagPicker />
      </div>
      <div className="container" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="navbar-header">
          <a className="navbar-brand" href="https://www.pureprofile.com">
            <img src={Logo} alt="" className="logo" />
          </a>
          <button
            type="button"
            className={collapsed ? '' : 'active'}
            data-toggle="collapse"
            aria-label="menu"
            aria-expanded="false"
            aria-controls="navbar"
            onClick={() => setCollapsed((c) => !c)}
          >
            <span className="icon-bar" />
          </button>
        </div>
        {renderNav()}
      </div>
    </header>
  );
};
