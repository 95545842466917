import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        outlined: {
          fontSize: '14px',
          fontWeight: '400',
          paddingTop: '6px',
          paddingBottom: '6px',
        },
      },
    },
  },
});
