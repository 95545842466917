import Cookies from 'universal-cookie';
import omit from 'lodash/omit';
import { PanelLanguage } from '../declarations/auth-api';
import { changeLanguage } from '../i18n';

export const selectLanguage = (language: PanelLanguage | null) => ({
  type: 'SELECT_LANGUAGE',
  language,
});

const cookies = new Cookies();
const cookieKey = 'selected_language';

const language = (state: PanelLanguage | null = null, action): PanelLanguage | null => {
  switch (action.type) {
    case 'SELECT_LANGUAGE': {
      const newVal = action.language ?? null;
      cookies.set(cookieKey, JSON.stringify(omit(newVal, 'isLocked')), { expires: new Date('2099') });
      if (newVal?.language_code) {
        changeLanguage(newVal.language_code);
      } else {
        changeLanguage('en');
      }
      return newVal;
    }
    default: {
      if (state == null) {
        const storedVal = cookies.get(cookieKey);
        if (storedVal) {
          return storedVal;
        }
      }
      return state ?? null;
    }
  }
};

export default language;
