import { getURLParam, joinSearchObj } from '../../../helpers/helpers';
import { getReactAppRedirectUrl } from '../../../app-config';
import { redirectTo } from '../../../helpers/redirect-to';

// ------------------------------------
// Constants
// ------------------------------------

export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const VERIFY_FAILED = 'VERIFY_FAILED';

// ------------------------------------

export const verifySuccess = () => ({
  type: VERIFY_SUCCESS,
});

export const verifyFailed = () => ({
  type: VERIFY_FAILED,
});

export const submit = () => (dispatch, getState) => {
  dispatch(verifySuccess());
  setTimeout(() => {
    const urlParam = getURLParam('token');
    const query = { token: urlParam };
    redirectTo(`${getReactAppRedirectUrl()}?${joinSearchObj(query)}`, false);
  }, 5000);
};

const initialState = {
  verified: false,
  verificationFailed: false,
};

const loginAuthorisation = (state = initialState, action) => {
  switch (action.type) {
    case 'VERIFY_SUCCESS':
      return {
        ...state,
        verified: true,
      };
    case 'VERIFY_FAILED':
      return {
        ...state,
        verificationFailed: true,
      };
    default:
      return state;
  }
};

export default loginAuthorisation;
