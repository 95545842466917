import Cookies from 'universal-cookie';
import omit from 'lodash/omit';
import { Country } from '../declarations/auth-api';

export const selectCountry = (country: Country | null) => ({
  type: 'SELECT_COUNTRY',
  country,
});

const cookies = new Cookies();
const cookieKey = 'selected_country';

const country = (state: Country | null = null, action): Country | null => {
  switch (action.type) {
    case 'SELECT_COUNTRY': {
      const newVal = action.country ?? null;

      // can't override with non-locked value
      if (state?.isLocked && !newVal.isLocked) {
        return state;
      }

      // make sure the isLocked is not stored in cookies
      cookies.set(cookieKey, JSON.stringify(omit(newVal, 'isLocked')), { expires: new Date('2099') });

      return newVal;
    }
    default: {
      if (state == null) {
        const storedVal = cookies.get(cookieKey);
        if (storedVal) {
          return storedVal;
        }
      }
      return state ?? null;
    }
  }
};

export default country;
