/* eslint-disable max-len */
/* eslint-disable no-process-env */

// Define endpoints
const endpoints = {
  auth: {
    // url: "https://staging-auth-api.pureprofile.com/",
    domainInfo: {
      type: 'GET',
      segment: `api/v1/public/domain-info?domain=${window.location.hostname.replace(/account/, 'my')}`,
    },
    countriesList: {
      type: 'GET',
      segment: `api/v1/info/countries?platformUuid=1efdfeb8-a14a-4eac-b66d-6c4929e4376c`,
    },
    registration: {
      type: 'POST',
      segment: 'api/v2/user/register',
    },
    login: {
      type: 'POST',
      segment: 'api/v1/user/login',
    },
    logout: {
      type: 'GET',
      segment: 'api/v1/user/logout',
    },
    getProfile: {
      type: 'GET',
      segment: 'api/v1/user/profile',
    },
    requestValidationEmail: {
      type: 'GET',
      segment: 'api/v1/user/request-validation-email',
    },
    changeUsername: {
      type: 'POST',
      segment: 'api/v1/user/change-username',
    },
    forgotPassword: {
      type: 'POST',
      segment: 'api/v1/user/forgot-password',
    },
    resetPassword: {
      type: 'POST',
      segment: 'api/v1/user/reset-password',
    },
    validateEmail: {
      type: 'POST',
      segment: 'api/v1/user/validate-email',
    },
    authorizeLogin: {
      type: 'GET',
      segment: 'api/v1/user/verify-device',
    },
    facebookOAuth: {
      type: 'POST',
      segment: 'oauth/facebook/id-token/sign-in',
    },
    twitterRequestToken: {
      type: 'POST',
      segment: 'oauth/twitter/id-token/request?app=gateway',
    },
    twitterAuthorizeToken: {
      type: 'POST',
      segment: 'oauth/twitter/id-token/authorize',
    },
    twitterOAuth: {
      type: 'POST',
      segment: 'oauth/twitter/id-token/sign-in',
    },
    googleOAuth: {
      type: 'POST',
      segment: 'oauth/google/id-token/sign-in',
    },
    location: {
      type: 'GET',
      segment: 'api/v1/info/location',
    },
    recaptcha: {
      type: 'GET',
      segment: 'api/v1/user/login/recaptcha-required',
    },
    recaptchaSignup: {
      type: 'GET',
      segment: 'api/v1/user/register/recaptcha-required',
    },
    getAffiliateInvite: {
      type: 'GET',
      segment: 'affiliates/invite/{inviteId}',
    },
    acceptAffiliateInvite: {
      type: 'POST',
      segment: 'affiliates/invite/{inviteId}/accept',
    },
    getCanChangeInstance: {
      type: 'GET',
      segment: 'api/v1/user/can-change-instance',
    },
    changeInstance: {
      type: 'POST',
      segment: 'api/v1/user/change-instance',
    },
  },
  ipApi: {
    url: 'https://www.trackip.net/',
    getip: {
      type: 'GET',
      segment: 'ip',
    },
  },
};

// Change apiUrl based on env
const conf = {
  mainUrl: process.env.REACT_APP_MAIN_URL,
  endpoints,
};

export default conf;
