import { preserveWindowQuery } from './links';

export function redirectTo(_url: string, preserveQuery: boolean) {
  const url = preserveQuery ? preserveWindowQuery(_url) : _url;
  const isInIframe = window.self !== window.top;
  if (isInIframe) {
    try {
      window.top!.location.href = url;
    } catch (ignoreErr) {
      window.open(url);
    }
  } else {
    window.location.href = url;
  }
}
