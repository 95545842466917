/* eslint-disable no-prototype-builtins */

import conf from '../conf/endpoints';
import i18n from '../i18n';
import { fetchToken } from './fetch-profile';

const parseJson = (json) => {
  try {
    return {
      response: JSON.parse(json),
      success: true,
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return {
      response: e,
      success: false,
    };
  }
};
const assemble = (literal, urlParams) => {
  if (literal == null) {
    return null;
  }
  return urlParams ? literal.replace(/{(\w+)}/g, (_, v) => urlParams[v]) : literal;
};

export interface IMakeCall {
  call: {
    section: string;
    job: string;
  };
  headers?: Record<string, string>;
  urlParams?: Record<string, string>;
  queryParams?: Record<string, string | null | undefined>;
  payload?: any;
}

export const makeCall = <T>({ call, headers, urlParams, queryParams, payload }: IMakeCall): Promise<T> => {
  const jobConf = conf.endpoints[call.section][call.job];
  const isWidget = window.location.pathname.startsWith('/widgets/');
  const requestObject: RequestInit = {
    referrerPolicy: 'unsafe-url',
    headers: {
      'Content-type': 'application/json',
      'X-App-Name': isWidget ? 'pp-gateway-widget' : 'pp-gateway',
      'X-App-Lang': i18n.language,
      ...headers,
    },
    method: jobConf.type,
    // mode: "no-cors"
  };

  if (call.section === 'auth') {
    requestObject.credentials = 'include';
    if (!requestObject.headers!['pp-token']) {
      const token = fetchToken();
      if (token) {
        requestObject.headers!['pp-token'] = token;
      }
    }
  }

  // handle urlParams
  const confUrl = conf.endpoints[call.section].url || conf.mainUrl;
  let endpointUrl = `${confUrl}${assemble(jobConf.segment, urlParams)}`;

  // handle queryParams
  if (queryParams) {
    const queryPairs: string[] = [];

    // eslint-disable-next-line guard-for-in
    for (const key in queryParams) {
      const val = queryParams[key];
      if (val && val !== 'undefined') {
        queryPairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
      }
    }

    if (queryPairs.length > 0) {
      endpointUrl += `?${queryPairs.join('&')}`;
    }
  }

  // handle payload
  if (payload) {
    if (jobConf.type === 'GET') {
      throw new Error(`GET request can't include a payload, use queryParams instead!`);
    } else {
      requestObject.body = JSON.stringify(payload);
    }
  }

  return fetch(endpointUrl, requestObject)
    .then(async (response) => {
      if (response.ok) {
        return parseJson(await response.text()).response;
      }
      const error = parseJson(await response.text());
      return Promise.reject(error.success ? error.response : response.statusText);
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      return Promise.reject(error);
    });
};

export default makeCall;
