/* eslint-disable no-process-env */

export type T_REACT_APP_ENV = 'development' | 'staging' | 'production';

const reactAppEnv = process.env.REACT_APP_ENV as T_REACT_APP_ENV;

export const parentFrameUrl = window.location !== window.parent.location ? document.referrer : document.location.href;

export function getAppEnv() {
  return reactAppEnv;
}

export function isProduction() {
  return reactAppEnv === 'production';
}

export function getFacebookAppId() {
  if (reactAppEnv === 'production') {
    // old: return '1389789521240469';
    return '253340617223906';
  }
  if (reactAppEnv === 'staging') {
    return '497528053758918';
  }
  // development
  return '762512763866939';
}

export function getFacebookApiVersion() {
  return 'v2.7';
}

export function getGoogleClientId() {
  if (reactAppEnv === 'production') {
    return '1073241114028-v56bu2coafv5vfit35rkvhp41n0ntf57.apps.googleusercontent.com';
  }
  if (reactAppEnv === 'staging') {
    return '716299749561-ep3fiusp820joo8qjh9n76g0vo44v0vt.apps.googleusercontent.com';
  }
  // development
  return '554342133120-0p3dfp8glqlc93ghmnab0frl948gd9rp.apps.googleusercontent.com';
}

export function getReactAppRedirectUrl(): string {
  return process.env.REACT_APP_REDIRECT_URL!;
}

export function getAccountPortalUrl(): string {
  if (reactAppEnv === 'production') {
    return 'https://account.pureprofile.com';
  }
  if (reactAppEnv === 'staging') {
    return 'https://staging-account.pureprofile.com';
  }
  // development
  return 'https://dev-account.pureprofile.com';
}

export function getAuthApiUrl(): string {
  let authApiUrl = process.env.REACT_APP_MAIN_URL!;
  if (authApiUrl.endsWith('/')) {
    authApiUrl = authApiUrl.slice(0, -1);
  }
  return authApiUrl;
}

export function getAppleClientId(): string {
  return process.env.REACT_APP_APPLE_CLIENT_ID!;
}

export const log = {
  debug: (...args: any[]) => {
    if (!isProduction()) {
      // eslint-disable-next-line no-console
      console.debug(...args);
    }
  },
  error: (...args: any[]) => {
    // eslint-disable-next-line no-console
    console.error(...args);
  },
};

// TODO: REACT_APP_GOOGLE_ANALYTICS_TAG
