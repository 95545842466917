import { combineReducers } from 'redux';
import login from '../routes/Login/reducers/Login';
import forgot_password from '../routes/ForgotPassword/reducers/ForgotPassword';
import emailVerification from '../routes/EmailVerification/reducers/EmailVerification';
import loginAuthorisation from '../routes/LoginAuthorisation/reducers/LoginAuthorisation';
import country from './country';
import language from './language';

const myApp = combineReducers({
  login,
  forgot_password,
  emailVerification,
  loginAuthorisation,
  country,
  language,
});

export default myApp;
