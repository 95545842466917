import Cookies from 'universal-cookie';
import makeCall from './caller';
import { AccountHolderProfile } from '../declarations/auth-api';
import { getQueryVar } from './helpers';

// returns validated AhProfile or null

const cookies = new Cookies();

export function fetchToken(): string | null {
  let ppToken = cookies.get('pp-token');
  if (!ppToken) {
    ppToken = getQueryVar('token');
  }
  return ppToken;
}

export async function fetchProfile(): Promise<AccountHolderProfile | null> {
  const ppToken = fetchToken();
  if (!ppToken) {
    return null;
  }
  try {
    const data = await makeCall<AccountHolderProfile>({
      call: {
        section: 'auth',
        job: 'getProfile',
      },
      headers: {
        'pp-token': ppToken,
      },
    });
    return data;
  } catch (err) {
    // local cookie
    cookies.remove('pp-token');
    // as well as global cookie
    cookies.remove('pp-token', {
      domain: window.location.hostname.split('.').slice(-2).join('.'), // pureprofile.com
    });
    return null;
  }
}
