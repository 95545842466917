import Cookies from 'universal-cookie';
import { parseUrl, stringifyUrl } from 'query-string';
import { joinSearchObj, getQueryVar } from './helpers';
import { getReactAppRedirectUrl } from '../app-config';
import { AccountHolderLoginResponse } from '../declarations/auth-api';
import { redirectTo } from './redirect-to';

function isPureprofileComDomain(url: string) {
  return /^https:\/\/[^/]+\.pureprofile\.com\//.test(url);
}

export function afterLogin(profile: AccountHolderLoginResponse) {
  const { ppToken, acceptedTermsConditions, emailValidated, emailValidationReminder } = profile;

  const cookies = new Cookies();
  cookies.set('pp-token', ppToken);

  if (!acceptedTermsConditions) {
    // PPD-5066: terms and conditions have to be accepted before letting user into the app
    if (window.location.pathname.startsWith('/accept-terms-and-conditions')) {
      // prevent infinite redirect
      return;
    }
    return redirectTo(`/accept-terms-and-conditions?token=${ppToken}`, true);
  }

  if (!emailValidated && emailValidationReminder) {
    // PPD-4825: do not send user to the app yet
    if (window.location.pathname.startsWith('/validate-email')) {
      // prevent infinite redirect
      return;
    }
    return redirectTo(`/validate-email?token=${ppToken}`, true);
  }

  const redirect = getQueryVar('redirect');
  if (
    redirect &&
    (redirect.startsWith(getReactAppRedirectUrl()) || isPureprofileComDomain(redirect)) &&
    !redirect.includes('/login') &&
    !redirect.includes('/logout')
  ) {
    const isAhApi = redirect.includes('ah-api.pureprofile.com');
    const tokenName = isAhApi ? 'pp-token' : 'token';
    const parsedUrl = parseUrl(redirect);
    parsedUrl.query[tokenName] = ppToken;

    if (isAhApi) {
      if (profile.instanceCode) {
        parsedUrl.query['ins-code'] = profile.instanceCode;
      }
      if (profile.panelCode) {
        parsedUrl.query['pnl-code'] = profile.panelCode;
      }
    }

    redirectTo(stringifyUrl(parsedUrl), false);
    return;
  }

  redirectTo(`${getReactAppRedirectUrl()}?${joinSearchObj({ token: ppToken })}`, false);
}
