import makeCall from '../../../helpers/caller';
import { validateEmail } from '../../../helpers/validators';

// ------------------------------------
// Constants
// ------------------------------------

export const UPDATE_FIELD_FP = 'UPDATE_FIELD_FP';
export const SET_ERRORS_FP = 'SET_ERRORS_FP';
export const EMAIL_SENT_SUCCESS = 'EMAIL_SENT_SUCCESS';
export const RESET_STATE = 'RESET_STATE';
export const TOGGLE_LOADING = 'TOGGLE_LOADING';

// ------------------------------------

export const updateFieldFP = (key, e) => ({
  type: UPDATE_FIELD_FP,
  value: e.target.value,
  key,
});

export const setErrorsFP = (errors) => ({
  type: SET_ERRORS_FP,
  errors,
});

export const emailSentSuccess = () => ({
  type: EMAIL_SENT_SUCCESS,
});

export const toggleLoading = () => ({
  type: TOGGLE_LOADING,
});

export const resetState = () => ({
  type: RESET_STATE,
});

export const submitForm = () => (dispatch, getState) => {
  const errors: any = {
    email_valid: false,
  };
  if (validateEmail(getState().forgot_password.email)) {
    errors.email_valid = true;
    errors.show_error = false;
  }

  if (!errors.email_valid) {
    dispatch(setErrorsFP(errors));
  } else {
    dispatch(toggleLoading());
    return makeCall({
      call: {
        section: 'auth',
        job: 'forgotPassword',
      },
      payload: {
        username: getState().forgot_password.email,
        platformKey: '1efdfeb8-a14a-4eac-b66d-6c4929e4376c',
      },
    })
      .then((data) => {
        dispatch(emailSentSuccess());
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        dispatch(toggleLoading());
        dispatch(
          setErrorsFP({
            show_error: true,
            error_message: err.message ? err.message : 'Something went wrong',
            email_valid: true,
          })
        );
      });
  }
};

const initialState = {
  email: null,
  email_valid: true,
  show_error: false,
  error_message: '',
  email_sent: false,
  loading: false,
};

const forgotPassword = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_FIELD_FP':
      return {
        ...state,
        [action.key]: action.value,
      };
    case 'SET_ERRORS_FP':
      return {
        ...state,
        email_valid: action.errors.email_valid,
        show_error: action.errors.show_error,
        error_message: action.errors.error_message,
      };
    case 'EMAIL_SENT_SUCCESS':
      return {
        ...state,
        email_sent: true,
      };
    case 'RESET_STATE':
      return {
        email: null,
        email_valid: true,
        show_error: false,
        email_sent: false,
      };
    case 'TOGGLE_LOADING':
      return {
        ...state,
        loading: !state.loading,
      };
    default:
      return state;
  }
};

export default forgotPassword;
