import keys from 'lodash/keys';
import omit from 'lodash/omit';
import { getQueryObj, joinSearchObj } from './helpers';

export const preserveWindowQuery = (path: string) => {
  const pathQueryObj = getQueryObj(path);
  const windowQueryObj = getQueryObj();
  const toAppend = joinSearchObj(omit(windowQueryObj, keys(pathQueryObj)));
  const appendChar = path.indexOf('?') === -1 ? '?' : '&';
  return toAppend ? path + appendChar + toAppend : path;
};
