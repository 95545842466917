/* eslint-disable max-len */

import 'react-toastify/dist/ReactToastify.css';
import './styles/main.scss';
import { Suspense } from 'react';
import * as History from 'history';
import { Route, Router, Switch } from 'react-router-dom';
import endsWith from 'lodash/endsWith';
import Loadable from 'react-loadable';
import ReactGA from 'react-ga';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from '@mui/material/styles';

import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans'; // 400 - default
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';
import '@fontsource/open-sans/800.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faPinterest } from '@fortawesome/free-brands-svg-icons/faPinterest';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import i18n from './i18n';
import { theme } from './theme';
import Loading from './components/Loading/Loading';
import { Header } from './components/Header/Header';
import { Spinner } from './components/spinner';
import { LanguageChecker } from './language-checker';

// eslint-disable-next-line no-process-env
const gaTag = process.env.REACT_APP_GOOGLE_ANALYTICS_TAG;
if (gaTag) {
  ReactGA.initialize(gaTag, {
    standardImplementation: true,
  });
}

const logPageView = (pathname, search) => {
  const pathnameWithoutTrailingSlash = endsWith(pathname, '/') && pathname !== '/' ? pathname.slice(0, -1) : pathname;
  ReactGA.pageview(`${pathnameWithoutTrailingSlash}${search}`);
};

const history = History.createBrowserHistory();

const { location } = history;
logPageView(location.pathname, location.search);

history.listen((loc: any) => {
  logPageView(loc.pathname, loc.search);
});

library.add(
  faFacebook,
  faFacebookF,
  faGoogle,
  faTwitter,
  faLinkedin,
  faLinkedinIn,
  faPinterest,
  faExclamationTriangle,
  faEye,
  faArrowLeft,
  faSpinner
);

// const Home = Loadable({
//   loader: () => import("./routes/Home/Home" /* webpackChunkName: "home" */),
//   loading: Loading,
// });

const Login = Loadable({
  loader: () => import('./routes/Login' /* webpackChunkName: "login" */),
  loading: Loading,
});

const LoginWidget = Loadable({
  loader: () => import('./routes/LoginWidget' /* webpackChunkName: "login-widget" */),
  loading: Loading,
});

const Signup = Loadable({
  loader: () => import('./routes/Signup' /* webpackChunkName: "signup" */),
  loading: Loading,
});

const SignupWidget = Loadable({
  loader: () => import('./routes/SignupWidget' /* webpackChunkName: "signup-widget" */),
  loading: Loading,
});

const AcceptTermsAndConditions = Loadable({
  loader: () =>
    import(
      './routes/AcceptTermsAndConditions/AcceptTermsAndConditions' /* webpackChunkName: "accepttermsandconditions" */
    ),
  loading: Loading,
});

const ValidateEmail = Loadable({
  loader: () => import('./routes/ValidateEmail/containers/ValidateEmail' /* webpackChunkName: "validateemail" */),
  loading: Loading,
});

const ForgotPassword = Loadable({
  loader: () => import('./routes/ForgotPassword/containers/ForgotPassword' /* webpackChunkName: "reset-password" */),
  loading: Loading,
});

const PasswordRecovery = Loadable({
  loader: () =>
    import('./routes/PasswordRecovery/components/PasswordRecovery' /* webpackChunkName: "password-recovery" */),
  loading: Loading,
});

const EmailVerification = Loadable({
  loader: () =>
    import('./routes/EmailVerification/containers/EmailVerification' /* webpackChunkName: "password-recovery" */),
  loading: Loading,
});

const LoginAuthorisation = Loadable({
  loader: () =>
    import('./routes/LoginAuthorisation/containers/LoginAuthorisation' /* webpackChunkName: "password-recovery" */),
  loading: Loading,
});

const UnsubscribeInvite = Loadable({
  loader: () => import('./routes/UnsubscribeInvite/UnsubscribeInvite' /* webpackChunkName: "unsubscribe-invite" */),
  loading: Loading,
});

const AffiliateInvite = Loadable({
  loader: () => import('./routes/AffiliateInvite/AffiliateInvite' /* webpackChunkName: "affiliate-invite" */),
  loading: Loading,
});

const Error = Loadable({
  loader: () => import('./routes/Error/Error' /* webpackChunkName: "error" */),
  loading: Loading,
});

const NoMatch = Loadable({
  loader: () => import('./components/NoMatch/NoMatch' /* webpackChunkName: "no-match" */),
  loading: Loading,
});

const App = () => {
  let renderHeader = true;
  if (window.location.pathname.startsWith('/widgets/') || window.location.pathname.startsWith('/unsubscribe/invite/')) {
    renderHeader = false;
  }
  return (
    <Suspense
      fallback={
        <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Spinner />
        </div>
      }
    >
      <I18nextProvider i18n={i18n}>
        <LanguageChecker>
          <ThemeProvider theme={theme}>
            {renderHeader && <Header />}
            <main className="main-wrapper">
              <Router history={history as any}>
                <Switch>
                  <Route component={Login} exact path="/" />
                  <Route component={Login} exact path="/login" />
                  <Route component={LoginWidget} exact path="/widgets/login" />
                  <Route component={AffiliateInvite} path="/join/invite/:inviteId" />
                  <Route component={Signup} path="/join" />
                  <Route component={SignupWidget} path="/widgets/join" />
                  <Route component={AcceptTermsAndConditions} path="/accept-terms-and-conditions" />
                  <Route component={ValidateEmail} path="/validate-email" />
                  <Route component={ForgotPassword} path="/reset-password" />
                  <Route component={PasswordRecovery} path="/password-recovery" />
                  <Route component={EmailVerification} path="/email-confirmation" />
                  <Route component={LoginAuthorisation} path="/login-authorisation" />
                  <Route component={UnsubscribeInvite} path="/unsubscribe/invite/:inviteId" />
                  <Route component={Error} path="/error" />
                  <Route component={NoMatch} />
                </Switch>
              </Router>
            </main>
          </ThemeProvider>
        </LanguageChecker>
      </I18nextProvider>
    </Suspense>
  );
};

export default App;
