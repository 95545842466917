import { AccountHolderLoginResponse } from '../../../declarations/auth-api';
import makeCall from '../../../helpers/caller';
import { getURLParam } from '../../../helpers/helpers';

// ------------------------------------
// Constants
// ------------------------------------

export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const VERIFY_FAILED = 'VERIFY_FAILED';

// ------------------------------------

// keep this in sync with auth-api
export interface ValidateEmailResult extends AccountHolderLoginResponse {
  status: 'ok' | 'already-verified';
  lotameId?: string | null;
  hasOffersId?: string | null;
  ppToken: string; // required by pp-gateway app
  emailValidated: boolean; // required by pp-gateway app
}

export const verifySuccess = (verificationResponse: ValidateEmailResult) => ({
  type: VERIFY_SUCCESS,
  verificationResponse,
});

export const verifyFailed = () => ({
  type: VERIFY_FAILED,
});

export const submit = () => (dispatch, getState) =>
  makeCall<ValidateEmailResult>({
    call: {
      section: 'auth',
      job: 'validateEmail',
    },
    payload: {
      token: getURLParam('token', window.location.href),
    },
  })
    .then((verificationResponse) => {
      dispatch(verifySuccess(verificationResponse));
    })
    .catch((err) => {
      dispatch(verifyFailed());
    });

const initialState = {
  verified: false,
  verificationFailed: false,
};

const emailVerification = (state = initialState, action) => {
  if (action.type === 'VERIFY_SUCCESS') {
    const a = action as ReturnType<typeof verifySuccess>;
    return {
      ...state,
      verified: true,
      verificationResponse: a.verificationResponse,
    };
  }
  if (action.type === 'VERIFY_FAILED') {
    return {
      ...state,
      verified: false,
      verificationFailed: true,
    };
  }
  return state;
};

export default emailVerification;
