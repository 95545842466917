/* eslint-disable max-len, no-useless-escape */

import { TFunction } from 'i18next';

export function validateEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function getPasswordError(t: TFunction, password: string | null | undefined): string | null {
  const minLength = 6;
  const maxLength = 50;
  if (!password || password.length < minLength) {
    return t('passwordHasToBeAtLeastNumLong', `Password has to be at least {{num}} characters long.`, {
      num: minLength,
    });
  }
  if (password.length > maxLength) {
    return t('passwordCantBeMoreThanNumLong', `Password can't be more than {{num}} characters long.`, {
      num: maxLength,
    });
  }
  if (/\s/.test(password)) {
    return t('passwordMustNotContainSpaces', `Password must not contain any spaces or blank characters.`);
  }
  if (password.search(/\d/) === -1) {
    return t('passwordNeedsToIncludeAtLeastOneNumber', `Password needs to include at least one number.`);
  }
  if (password.search(/[a-zA-Z]/) === -1) {
    return t('passwordNeedsToIncludeAtLeastOneLetter', `Password needs to include at least one letter.`);
  }
  return null;
}
