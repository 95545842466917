import { FC } from 'react';
import { changeLanguage, useAppTranslation } from './i18n';
import { getQueryObj, setQueryObj } from './helpers/helpers';

export const LanguageChecker: FC = ({ children }) => {
  const { i18n } = useAppTranslation();

  // lang in query
  const query = getQueryObj();
  if (query.lang) {
    if (i18n.language !== query.lang) {
      changeLanguage(query.lang);
    }
    delete query.lang;
    setQueryObj(query);
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
