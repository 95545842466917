import { toast } from 'react-toastify';
import makeCall, { IMakeCall } from '../../../helpers/caller';
import { validateEmail } from '../../../helpers/validators';
import { getQueryVar } from '../../../helpers/helpers';
import { fetchProfile } from '../../../helpers/fetch-profile';
import { afterLogin } from '../../../helpers/after-login';
import { AccountHolderLoginResponse } from '../../../declarations/auth-api';
import { parentFrameUrl } from '../../../app-config';

// ------------------------------------
// Constants
// ------------------------------------

export const UPDATE_FIELD = 'UPDATE_FIELD';
export const SET_ERRORS = 'SET_ERRORS';
export const PASSWORD_EXPIRED = 'PASSWORD_EXPIRED';
export const TOGGLE_LOADING = 'TOGGLE_LOADING';
export const SET_FACEBOOK_ERROR = 'SET_FACEBOOK_ERROR';
export const VERIFY_RECAPTCHA = 'VERIFY_RECAPTCHA';
export const ACTIVATE_RECAPTCHA = 'ACTIVATE_RECAPTCHA';
export const RESET_RECAPTCHA = 'RESET_RECAPTCHA';
export const TOGGLE_PASSWORD = 'TOGGLE_PASSWORD';

// ------------------------------------

export const updateField = (key, e) => ({
  type: UPDATE_FIELD,
  value: e.target.value,
  key,
});

export const setErrors = (errors) => ({
  type: SET_ERRORS,
  errors,
});

export const verifyRecaptcha = (gRecaptchaResponse) => ({
  type: VERIFY_RECAPTCHA,
  gRecaptchaResponse,
});

export const activateRecaptcha = () => ({
  type: ACTIVATE_RECAPTCHA,
});

export const resetRecaptcha = () => ({
  type: RESET_RECAPTCHA,
});

export const passwordExpired = () => ({
  type: PASSWORD_EXPIRED,
});

export const toggleLoading = () => ({
  type: TOGGLE_LOADING,
});
export const setFacebookError = (err) => ({
  type: SET_FACEBOOK_ERROR,
  err,
});
export const togglePassword = () => ({
  type: TOGGLE_PASSWORD,
});
export const loginSuccess = (profile: AccountHolderLoginResponse) => (dispatch, getState) => {
  afterLogin(profile);
};

export const loginFailed = (err, recaptchaRef) => (dispatch, getState) => {
  if (recaptchaRef) {
    recaptchaRef.reset();
    dispatch(resetRecaptcha());
  }
  if (err.data && err.data.status === 4) {
    toast.error('Your account has been locked, please reset your password', {
      position: toast.POSITION.TOP_CENTER,
    });
  } else if (err.data && err.data.code === 'expired_password') {
    dispatch(passwordExpired());
  } else if (err.data && err.data.code === 'recaptcha-required') {
    dispatch(activateRecaptcha());
  } else if (/^4/.test(err.statusCode) && err.message) {
    toast.error(err.message, {
      position: toast.POSITION.TOP_CENTER,
    });
  } else if (err.statusCode === 400) {
    toast.error('The provided email does not belong to any account', {
      position: toast.POSITION.TOP_CENTER,
    });
  } else if (err.statusCode === 401) {
    toast.error('The provided email and password do not match', {
      position: toast.POSITION.TOP_CENTER,
    });
  } else if (err.statusCode === 403) {
    toast.error(err.message, {
      position: toast.POSITION.TOP_CENTER,
    });
  } else {
    toast.error('Our login services are currently unavailable. Please try again later.', {
      position: toast.POSITION.TOP_CENTER,
    });
  }
};

export const checkEnter = (e, recaptchaRef) => (dispatch, getState) => {
  const code = e.keyCode ? e.keyCode : e.which;
  if (code === 13) {
    // Enter keycode
    dispatch(submitForm(recaptchaRef));
  }
};

export const checkCookie = () => (dispatch, getState) => {
  void fetchProfile().then((profile) => {
    if (profile) {
      dispatch(loginSuccess(profile));
    }
  });
};

export const checkRecaptchaRequired = () => (dispatch, getState) =>
  makeCall<{
    required: boolean;
  }>({
    call: {
      section: 'auth',
      job: 'recaptcha',
    },
  })
    .then((data) => {
      if (data.required) {
        dispatch(activateRecaptcha());
      }
    })
    .catch((err) => {
      // do nothing
    });

export const submitForm = (recaptchaRef) => (dispatch, getState) => {
  const errors: any = {
    email_valid: false,
    password_valid: false,
  };
  if (validateEmail(getState().login.email)) {
    errors.email_valid = true;
  }

  if (getState().login.password) {
    errors.password_valid = true;
  }

  if (!errors.email_valid || !errors.password_valid) {
    dispatch(setErrors(errors));
  } else {
    dispatch(toggleLoading());
    const callParams: IMakeCall = {
      call: {
        section: 'auth',
        job: 'login',
      },
      payload: {
        username: getState().login.email,
        password: getState().login.password,
        platformKey: '1efdfeb8-a14a-4eac-b66d-6c4929e4376c',
        parentFrameUrl,
      },
    };
    if (getState().login.recaptchaActive) {
      callParams.payload.recaptchaResponse = getState().login.gRecaptchaResponse;
    }
    return makeCall<AccountHolderLoginResponse>(callParams)
      .then((data) => {
        dispatch(loginSuccess(data));
      })
      .catch((err) => {
        dispatch(toggleLoading());
        dispatch(loginFailed(err, recaptchaRef));
      });
  }
};

const initialState = {
  email: getQueryVar('suggest-login') || null,
  email_valid: true,
  gRecaptchaResponse: null,
  loading: false,
  password: null,
  passwordShown: false,
  password_expired: false,
  password_valid: true,
  recaptchaActive: false,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        [action.key]: action.value,
        email_valid: action.key === 'email' ? true : state.email_valid,
      };
    case 'SET_ERRORS':
      return {
        ...state,
        email_valid: action.errors.email_valid,
        password_valid: action.errors.password_valid,
      };
    case 'VERIFY_RECAPTCHA':
      return {
        ...state,
        gRecaptchaResponse: action.gRecaptchaResponse,
      };
    case 'ACTIVATE_RECAPTCHA':
      return {
        ...state,
        recaptchaActive: true,
      };
    case 'RESET_RECAPTCHA':
      return {
        ...state,
        gRecaptchaResponse: null,
      };
    case 'PASSWORD_EXPIRED':
      return {
        ...state,
        password_expired: !state.password_expired,
      };
    case 'TOGGLE_LOADING':
      return {
        ...state,
        loading: !state.loading,
      };
    case SET_FACEBOOK_ERROR:
      return {
        ...state,
        facebookError: action.err,
      };
    case 'TOGGLE_PASSWORD':
      return {
        ...state,
        passwordShown: !state.passwordShown,
      };
    default:
      return state;
  }
};

export default login;
