/* eslint-disable react/forbid-prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import { LoadingComponentProps } from 'react-loadable';

const Loading: React.FC<LoadingComponentProps> = (props) => {
  if (props.error) {
    return (
      <div>
        Error! <button onClick={props.retry}>Retry</button>
      </div>
    );
  }
  if (props.pastDelay) {
    return <div>Loading...</div>;
  }
  if (props.timedOut) {
    return (
      <div>
        Taking a long time... <button onClick={props.retry}>Retry</button>
      </div>
    );
  }
  return null;
};

Loading.propTypes = {
  error: PropTypes.object,
  pastDelay: PropTypes.bool as any,
  timedOut: PropTypes.bool as any,
};

Loading.defaultProps = {
  error: false,
  pastDelay: false,
  timedOut: false,
};

export default Loading;
